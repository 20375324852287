import { useI18n } from 'vue-i18n'

const statusFactory = (label: string) => {
  const { t } = useI18n()
  const status = {
    label: '',
    variant: ''
  }

  switch (label) {
    case 'pending':
      status['label'] = t('global.pending')
      status['variant'] = 'warning'
      break
    case 'processing':
      status['label'] = t('global.processing')
      status['variant'] = 'warning'
      break
    case 'rejected':
      status['label'] = t('global.rejected')
      status['variant'] = 'alert'
      break
    case 'incomplete':
      status['label'] = t('global.incomplete')
      status['variant'] = 'alert'
      break
    case 'splitted':
      status['label'] = t('global.splitted')
      status['variant'] = 'split'
      break
    case 'in_progress':
      status['label'] = t('global.in_progress')
      status['variant'] = 'waiting'
      break
    case 'accepted':
      status['label'] = t('global.accepted')
      status['variant'] = 'success'
      break
    case 'reimbursed':
      status['label'] = t('global.reimbursed')
      status['variant'] = 'info'
      break
    case 'paid':
      status['label'] = t('global.paid')
      status['variant'] = 'success'
      break
    case 'quote_accepted':
      status['label'] = t('global.accepted')
      status['variant'] = 'success'
      break
    case 'valid':
      status['label'] = t('global.valid')
      status['variant'] = 'success'
      break
    case 'to_be_renewed':
      status['label'] = t('global.to-be-renewed')
      status['variant'] = 'alert'
      break
    case 'pay_now':
      status['label'] = t('global.pay-now')
      status['variant'] = 'info'
      break
    case 'upload_now':
      status['label'] = t('global.upload-now')
      status['variant'] = 'info'
      break
  }
  return status
}

export default statusFactory
