
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import statusFactory from '@/helpers/status-factory'

class Props {
  variant!: string
  responsive?: string
  icon?: string
  label?: string
}
@Options({
  name: 'Tag',
  components: {
    Icon
  },
  methods: { statusFactory }
})

export default class Tag extends Vue.with(Props) {
  formattedVariant () {
    if (this.variant && this.variant.length) {
      return statusFactory(this.variant)['variant']
    }
  }

  formattedLabel () {
    if (this.variant && this.variant.length) {
      return statusFactory(this.variant)['label']
    }
  }

  tagClass () {
    const tagClasses = {
      'tag': true,
      'is-responsive': this.responsive === ''
    }
    tagClasses[`tag--${this.formattedVariant()}`] = true
    return tagClasses
  }

  tagLabel () {
    if (this.label) {
      return this.label
    } else {
      return this.formattedLabel()
    }
  }

  get iconName () {
    if (this.icon) {
      return this.icon
    } else {
      switch (this.formattedVariant()) {
        case 'success':
          return 'check-circle'
        case 'alert':
          return 'alert-circle'
        case 'warning':
          return 'clock'
        case 'waiting':
          return 'clock'
        case 'info':
          return 'eye'
        case 'error':
          return 'x-circle'
        case 'split':
          return 'scissors'
        case 'user':
          return 'user'
      }
    }
  }

  normalise (item: string) {
    return item.replace('_', ' ')
  }
}
