<template>
  <h1 class="display-1 mb-l">{{ $t('packages.title')}}</h1>
  <div :class="[ loading ? 'is-loading' : '', 'packages']">
    <PackagePreview
        :currentPackage="packageService.currentPackage"
        v-for="item in packageService.packages"
        :item="item"
        v-bind:key="item.title"
    />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import PackagePreview from '@/03-organisms/Package-preview.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Packages',
  components: {
    PackagePreview,
    BackButton
  }
})
export default class PackageService extends Vue {
  packageService = inject(PackageServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loading = true

  beforeMount () {
    Promise.all([
      this.packageService?.loadPackages(),
      this.packageService?.loadCurrentPackage()
    ]).then(() => {
      this.loading = false
    }).catch((error) => {
      this.errorHandlerService?.loadError(error.response.data)
    })
  }
}
</script>
<style lang="scss" scoped>
.packages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: $xmedium;
  max-width: 850px;

  @include breakpoint($packages-breakpoint down) {
    grid-template-columns: 1fr;
    grid-row-gap: $medium;
  }
}
</style>
