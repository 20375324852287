<template>
  <div :class="boxClass">
    <Icon class="help-text__icon" :icon="icon"></Icon>
    <span class="fw-700 fs-14"><slot>template</slot></span>
  </div>
</template>
<script lang="ts">
import { Options, prop, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  variant?: string
  icon = prop<string>({ default: 'info' })
}

@Options({
  name: 'Help-text',
  components: {
    Icon
  }
})

/**
 * @param {string} variant - default: undefined, alert
 * @param {string} icon - default: info
 */
export default class HelpText extends Vue.with(Props) {
  get boxClass () {
    const classes = { }
    classes['help-text'] = true
    if (this.variant === 'alert') {
      classes['background background--red-15'] = true
    } else {
      classes['grey-dark'] = true
    }
    return classes
  }
}
</script>
<style lang="scss" scoped>
  .help-text {
    display: flex;
    align-items: center;
  }

  .help-text__icon {
    flex-shrink: 0;
    margin-right: 15px;
  }

  .background {
    border-radius: 20px;
    padding: 17px 27px;
  }
</style>
