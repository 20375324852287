
import { Options, Vue, prop } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { Package } from '@/services/packages/PackageService'
import { postPackageRequest } from '@/api/packages/packages-api'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

class Props {
  item?: Package;
  currentPackage?: Package;
}

@Options({
  name: 'Package-preview',
  components: {
    LoadingDots,
    IconButton,
    Button,
    PackageTag
  }
})
export default class PackagePreview extends Vue.with(Props) {
  isLoading = false
  errorHandlerService = inject(ErrorHandlerKey)
  packagesService = inject(PackageServiceKey)

  clickUpgrade (id: string) {
    this.isLoading = true
    postPackageRequest(id)
      .then(() => {
        this.updatePackageRequest()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  updatePackageRequest () {
    this.packagesService?.loadPackageRequest(true)
      .then(() => {
        this.forwardToCurrentPackage()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  forwardToCurrentPackage () {
    this.$router.push({ name: 'Package' })
  }
}
