<template>
  <BackButton :to="{name: 'Packages'}" />
  <h1 class="display-1 mb-l">{{ $t('packages.package.title')}}</h1>
  <div class="packages-package__request mb-l" v-if="packageService.packageRequest.newPackage">
    <h2 class="packages-package__request-status fw-700 tt-uppercase mt-0">
      <span class="mr-m mb-m">{{ $t('packages.package.request-title', {upgrade: packageService.packageRequest.newPackage.name }) }}</span>
      <Tag class="mb-m" :variant="packageService.packageRequest.status" />
    </h2>
    <table class="packages-package__request-table mb-l">
      <tr>
        <th v-if="packageService.packageRequest.submittedAt">{{ $t('global.submission-date')}}</th>
        <th v-if="packageService.packageRequest.paidAt">{{ $t('global.payed-on')}}</th>
      </tr>
      <tr>
        <td v-if="packageService.packageRequest.submittedAt">{{ formatDate(packageService.packageRequest.submittedAt) }}</td>
        <td v-if="packageService.packageRequest.paidAt">{{ formatDate(packageService.packageRequest.paidAt) }}</td>
      </tr>
    </table>
    <HelpText v-if="packageService.currentPackage && packageService.recommendedPackage">
      {{ $t('packages.package.upgrade-label', {current: packageService.currentPackage.name , upgrade: packageService.recommendedPackage.packageDetails.name }) }}
    </HelpText>
  </div>
  <div :class="[ loading ? 'is-loading' : '', 'packages-package__current mb-l']" v-if="packageService.currentPackage">
    <div class="packages-package__current-inner">
      <h2 class="fw-700 tt-uppercase mb-l mt-0">{{ $t('packages.package.current-package.title')}}</h2>
      <PackageTag class="mb-l" :package="packageService.currentPackage" />
      <span class="d-b tt-uppercase mb-m fs-12">{{ $t('packages.package.payment-frequency.label')}}</span>
      <span class="d-b fw-700 mb-xl placeholder-animation">{{ membersService.currentMember.paymentFrequency }}</span>
      <h3 class="fw-700 tt-uppercase">{{ $t('packages.package.services.label')}}</h3>
      <div class="packages-package__current-content format-text grey-dark mb-l placeholder-animation"
           v-html="packageService.currentPackage.description" />
      <Button :href="packageService.currentPackage.detailsUrl">{{ $t('global.learn-more-button')}}</Button>
    </div>
    <div class="packages-package__current-media">
      <img class="packages-package__current-media__image" :src="packageService.currentPackage.imageUrl" alt="" />
    </div>
  </div>
    <div class="packages-package__upgrade" v-if="packageService.recommendedPackage.packageDetails && !packageService.packageRequest">
      <span class="packages-package__upgrade-button fw-700">
        <PackageTag :package="packageService.recommendedPackage?.packageDetails" />
      </span>
      <h2 class="display-2">{{ $t('packages.package.upgrade.title', {upgrade: packageService.recommendedPackage.packageDetails.name }) }}</h2>
      <p class="grey-dark mb-l">{{ $t('packages.package.upgrade.label', {upgrade: packageService.recommendedPackage.packageDetails.name }) }}</p>
      <HelpText class="mb-l">{{ $t('packages.package.upgrade.info', {current: packageService.currentPackage.name , upgrade: packageService.recommendedPackage.packageDetails.name }) }}</HelpText>
      <Button :href="packageService.recommendedPackage.detailsUrl">{{ $t('global.learn-more-button')}}</Button>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Tag from '@/01-atoms/Tag.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { ErrorHandlerKey, MemberServiceKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Package',
  components: {
    IconButton,
    Tag,
    HelpText,
    PackageTag,
    Button,
    BackButton
  }
})

export default class PackagesPackage extends Vue {
  packageService = inject(PackageServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  membersService = inject(MemberServiceKey)
  loading = true

  beforeMount () {
    Promise.all([
      this.packageService?.loadCurrentPackage(),
      this.membersService?.loadCurrentMember(),
      this.packageService?.loadRecommendedPackage(),
      this.packageService?.loadPackageRequest()
    ]).then(() => {
      this.loading = false
    }).catch((error) => {
      this.errorHandlerService?.loadError(error.response.data)
    })
  }
}
</script>
<style lang="scss" scoped>
.packages-package__request {
  @include white-box;
}

.packages-package__request-table {
  max-width: 400px;
  width: 100%;

  th {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 7px;
  }

  td {
    font-weight: 700;
  }
}

.packages-package__current {
  @include white-box;
  display: flex;
  grid-gap: $xmedium;

  @include breakpoint(large down) {
    flex-direction: column-reverse;
  }
}

.packages-package__current-content {
  &.format-text {
    ul {
      list-style: disc;
      padding-left: 20px;

      li {
        padding: 5px 0;
      }
    }
  }
}

.packages-package__current-media {
  max-height: 450px;
  flex-shrink: 0;
  opacity: 1;
  transition: opacity 0.2s ease;
  width: 450px;

  .is-loading & {
    opacity: 0;
  }

  @include breakpoint(large down) {
    height: 300px;
    width: 300px;
  }

  @include breakpoint(medium down) {
    width: 100%;
  }
}

.packages-package__current-media__image {
  @include object-fit;
  border-radius: 15px;
}

.packages-package__upgrade {
  @include white-box;
  margin-top: 45px;
  position: relative;
}

.packages-package__upgrade-button {
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  transform: translateY(-50%);
}

.packages-package__request-status {
  display: flex;
  align-items: center;

  @include breakpoint(medium down) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.packages-package__current-inner {
  flex-grow: 1;
}

.partner__inner,
.partner__inner .text-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.partner__inner .content,
.partner__inner .partner-fixed-height {
 flex-grow: 1;
}

// and remove the min-height
</style>
