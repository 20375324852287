<template>
  <span :class="tagClass()">
    <Icon class="tag__icon" :icon="iconName" />
    <span class="tag__label">
      {{ tagLabel() }}
    </span>
    <div style="display: none">
      {{ $t('global.pending') }}
      {{ $t('global.processing') }}
      {{ $t('global.rejected') }}
      {{ $t('global.incomplete') }}
      {{ $t('global.splitted') }}
      {{ $t('global.in_progress') }}
      {{ $t('global.accepted') }}
      {{ $t('global.reimbursed') }}
      {{ $t('global.paid') }}
      {{ $t('global.accepted') }}
      {{ $t('global.valid') }}
      {{ $t('global.to-be-renewed') }}
      {{ $t('global.pay-now') }}
      {{ $t('global.upload-now') }}
    </div>
  </span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import statusFactory from '@/helpers/status-factory'

class Props {
  variant!: string
  responsive?: string
  icon?: string
  label?: string
}
@Options({
  name: 'Tag',
  components: {
    Icon
  },
  methods: { statusFactory }
})

export default class Tag extends Vue.with(Props) {
  formattedVariant () {
    if (this.variant && this.variant.length) {
      return statusFactory(this.variant)['variant']
    }
  }

  formattedLabel () {
    if (this.variant && this.variant.length) {
      return statusFactory(this.variant)['label']
    }
  }

  tagClass () {
    const tagClasses = {
      'tag': true,
      'is-responsive': this.responsive === ''
    }
    tagClasses[`tag--${this.formattedVariant()}`] = true
    return tagClasses
  }

  tagLabel () {
    if (this.label) {
      return this.label
    } else {
      return this.formattedLabel()
    }
  }

  get iconName () {
    if (this.icon) {
      return this.icon
    } else {
      switch (this.formattedVariant()) {
        case 'success':
          return 'check-circle'
        case 'alert':
          return 'alert-circle'
        case 'warning':
          return 'clock'
        case 'waiting':
          return 'clock'
        case 'info':
          return 'eye'
        case 'error':
          return 'x-circle'
        case 'split':
          return 'scissors'
        case 'user':
          return 'user'
      }
    }
  }

  normalise (item: string) {
    return item.replace('_', ' ')
  }
}
</script>

<style lang="scss" scoped>
// Blue tag white text
.tag {
  display: inline-flex;
  align-items: center;
  background-color: $blue-dark;
  border-radius: 30px;
  color: white;
  font-size: 14px;
  height: 25px;
  text-transform: capitalize;
  padding: 2px 9px 2px 4px;

  &.is-responsive {
    @include breakpoint(medium down) {
      padding: 0 5px;
      width: 25px;
    }
  }

  &--waiting {
    background: $grey-light;
    color: $grey-dark;
  }

  &--split,
  &--success {
    background: $green-15;
    color: $green;
  }
  &--alert,
  &--error {
    background-color: $red-15;
    color: $red;
  }
  &--warning {
    background-color: $blue-dark-10;
    color: $blue-dark;
  }
  &--info,
  &--user {
    background-color: $blue-dark;
    color: $white;
  }
}

.tag__icon {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  pointer-events: none;

  .tag.is-responsive & {
    @include breakpoint(medium down) {
      margin-right: 0;
    }
  }
}

.tag__label {
  pointer-events: none;

  .tag.is-responsive & {
    @include breakpoint(medium down) {
      display: none;
    }
  }
}
</style>
