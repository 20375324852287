
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import router from '@/router'

class Props {
  to!: string
  fallback?: string
}

@Options({
  name: 'Back-button',
  components: {
    IconButton
  }
})

/**
 * Styled back button
 *
 * @param {router-link} to - fallback for history.back()
 * @param {attribute} fallback - force fallback
 */
export default class BackButton extends Vue.with(Props) {
  clickButton () {
    this.checkInternalHistory()
  }

  checkInternalHistory () {
    if (window.history.state.back === null) {
      router.push(this.to)
    } else {
      this.checkForcedFallBack()
    }
  }

  checkForcedFallBack () {
    if (this.fallback === '') {
      router.push(this.to)
    } else {
      this.checkLoginPage()
    }
  }

  checkLoginPage () {
    if (window.history.state.back.split('/')[1] === 'login') {
      router.push(this.to)
    } else {
      router.back()
    }
  }
}
