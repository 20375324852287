
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Tag from '@/01-atoms/Tag.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { ErrorHandlerKey, MemberServiceKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Package',
  components: {
    IconButton,
    Tag,
    HelpText,
    PackageTag,
    Button,
    BackButton
  }
})

export default class PackagesPackage extends Vue {
  packageService = inject(PackageServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  membersService = inject(MemberServiceKey)
  loading = true

  beforeMount () {
    Promise.all([
      this.packageService?.loadCurrentPackage(),
      this.membersService?.loadCurrentMember(),
      this.packageService?.loadRecommendedPackage(),
      this.packageService?.loadPackageRequest()
    ]).then(() => {
      this.loading = false
    }).catch((error) => {
      this.errorHandlerService?.loadError(error.response.data)
    })
  }
}
