<template>
  <router-link :to="{name: 'Package'}">
    <div class="package-preview ta-center" :class="[item.isActive && 'is-active', currentPackage && currentPackage.id === item.id && 'package-preview--current']">
      <span v-if="currentPackage && currentPackage.id === item.id" class="package-preview__current display-3 background--blue-dark-10">
        {{ $t('packages.current-package-label') }}
      </span>
      <PackageTag class="mb-m" :package="item"></PackageTag>
      <span class="display-3 mb-l d-b">{{ $t('packages.benefits') }}</span>
      <p class="grey-dark mb-xl">{{ item.benefits }}</p>
      <span class="fs-34 fw-700 d-b mb-s blue-dark">{{ item.feeMonthly }}€</span>
      <span class="display-3 d-b mb-l">{{ $t('packages.monthly') }}</span>
      <span class="grey-dark d-b mb-l">{{ item.feeAnnually }}€ / {{ $t('packages.yearly') }}</span>
      <LoadingDots v-if="isLoading" />
      <div v-else>
        <IconButton icon="plus"
                    type="button"
                    v-if="item.type === 'upgrade'"
                    @click="clickUpgrade(item.id)"
                    :disabled="packagesService.packageRequest.id">{{ $t('packages.upgrade') }}</IconButton>
        <IconButton icon="minus"
                    type="button"
                    background="blue-dark-10"
                    v-else-if="item.type === 'downgrade'"
                    @click="clickUpgrade(item.id)"
                    :disabled="packagesService.packageRequest.id">{{ $t('packages.downgrade') }}</IconButton>
        <IconButton :to="{name: 'Package'}"
                    type="button"
                    icon="package"
                    background="blue-dark-10"
                    v-else>{{ $t('packages.current') }}</IconButton>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Button from '@/01-atoms/Button.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { Package } from '@/services/packages/PackageService'
import { postPackageRequest } from '@/api/packages/packages-api'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

class Props {
  item?: Package;
  currentPackage?: Package;
}

@Options({
  name: 'Package-preview',
  components: {
    LoadingDots,
    IconButton,
    Button,
    PackageTag
  }
})
export default class PackagePreview extends Vue.with(Props) {
  isLoading = false
  errorHandlerService = inject(ErrorHandlerKey)
  packagesService = inject(PackageServiceKey)

  clickUpgrade (id: string) {
    this.isLoading = true
    postPackageRequest(id)
      .then(() => {
        this.updatePackageRequest()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  updatePackageRequest () {
    this.packagesService?.loadPackageRequest(true)
      .then(() => {
        this.forwardToCurrentPackage()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  forwardToCurrentPackage () {
    this.$router.push({ name: 'Package' })
  }
}
</script>
<style lang="scss" scoped>

.package-preview {
  @include white-box;
  margin-top: 15px;
  padding-top: 40px;
  position: relative;

  &--current {
    box-shadow: 0 0 0 6px $blue-dark-10;
    margin-top: 0;
    padding-top: 45px;

    @include breakpoint($packages-breakpoint down) {
      margin-top: 30px;
    }
  }
}

.package-preview__current {
  border: none;
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 23px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: fit-content;
}
</style>
