
import { Options, Vue } from 'vue-class-component'
import PackagePreview from '@/03-organisms/Package-preview.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'

@Options({
  name: 'Packages',
  components: {
    PackagePreview,
    BackButton
  }
})
export default class PackageService extends Vue {
  packageService = inject(PackageServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loading = true

  beforeMount () {
    Promise.all([
      this.packageService?.loadPackages(),
      this.packageService?.loadCurrentPackage()
    ]).then(() => {
      this.loading = false
    }).catch((error) => {
      this.errorHandlerService?.loadError(error.response.data)
    })
  }
}
