
import { Options, prop, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  variant?: string
  icon = prop<string>({ default: 'info' })
}

@Options({
  name: 'Help-text',
  components: {
    Icon
  }
})

/**
 * @param {string} variant - default: undefined, alert
 * @param {string} icon - default: info
 */
export default class HelpText extends Vue.with(Props) {
  get boxClass () {
    const classes = { }
    classes['help-text'] = true
    if (this.variant === 'alert') {
      classes['background background--red-15'] = true
    } else {
      classes['grey-dark'] = true
    }
    return classes
  }
}
